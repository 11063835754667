<template>
    <div v-if="active" class="fixed z-40 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div id="modal"
                 class="mobile-y-compensator w-full inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">
                    <div class="flex justify-between items-center mb-2">
                        <div class="sm:mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                Mail de bon
                            </h3>
                        </div>
                        <modal-close @close="close()" />
                    </div>

                    <div class="default-input">
                        <input type="email" v-model="email" :readonly="mode === 'pos'" />
                    </div>

                    <keyboard
                        v-if="mode === 'pos'"
                        @input="changed"
                        :layouts="[
                        '1234567890{backspace:backspace}|qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm_,.-|{space:space}',
                        '!@#$%^&*(){backspace:backspace}|QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM:;|{space:space}'
                    ]"
                        :maxlength="16"
                    ></keyboard>
                </div>
                <div class="modal-footer p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                    <a @click="send()"
                        class="button button-wide button-large button-filled">

                        <svg v-if="loading" class="animate-spin mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>

                        Verstuur
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

// mixins
import KeyboardHelper from '../mixins/keyboard.helper.js';
import ModalClose from "./ModalClose";

export default {
    name: 'ModalMailReceipt',
    mixins: [KeyboardHelper],
    props: {
      mode: {
        type: String,
        default: 'pos', // pos, mobilepos
      },
      transaction_prop: {
        type: Object,
        required: true,
      },
    },
    components: {
        ModalClose,
    },
    data () {
        return {
            loading: false,
            active: false,
            email: '',
        };
    },

    methods: {
        open () {
            this.active = true;
        },

        close () {
            this.active = false;
            this.email = '';
        },

        changed(value) {
            if (value.length > 0)
                this.email += value
            else {
                this.email = this.email.slice(0, -1)
            }
        },

        async send () {
            this.loading = true;

            await axios.post('/transaction/mail', {
                email: this.email,
                transaction_id: this.transaction_prop.id,
                extended: true,
            }).then(response => {
                console.log(response);

                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Mail verstuurd",
                }, 2000);

                this.close();
                this.loading = false;
                this.email = '';

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Mail kan niet verstuurd worden.",
                }, 2000);
            });
        },
    },
    computed: {
        transaction () {
            return this.$store.getters['transaction/transaction'];
        },
    },
};
</script>
