<script>
import PaymentTypeSelect from "@/components/mobilepos/PaymentTypeSelect.vue";
import ModalMailReceipt from "@/components/ModalMailReceipt.vue";
import NavigationMobilePos from "@/components/NavigationMobilePos.vue";
import PrintReceipt from "@/prints/receipt";
import axios from "axios";

const DEFAULT_FILTERS = {
  date: "",
  table: "",
  payment_type: "",
  amount: null,
};

export default {
  name: "RecentTransactions",

  components: {
    PaymentTypeSelect,
    ModalMailReceipt,
    NavigationMobilePos,
  },

  data: () => ({
    tables: [],
    transactions: [],
    transactions_total: 0,
    pendingTransaction: null,
    filters: {
      ...DEFAULT_FILTERS,
    },
  }),

  mounted() {
    this.transactionGet();

    this.$watch(
      () => this.filters,
      () => this.transactionGet(),
      { deep: true },
    );
  },

  methods: {
    mailReceipt(transaction) {
      this.pendingTransaction = transaction;
      this.$refs.mailreceipt.open();
    },

    async printReceipt(transaction_id) {
      axios.get("/transactions/" + transaction_id)
        .then(response => {
          new PrintReceipt().print(response.data.data);
        })
        .catch(() => {
          this.$notify({
            group: "notifications",
            type: "error",
            title: "Fout opgetreden",
            text: "Oeps er ging iets fout..",
          }, 2000);
        });
    },

    async transactionGet() {
      const params = { ...this.filters };

      await axios.get("/transactions/latest", {
        params,
      }).then(response => {
        this.transactions = response.data.transactions;
        this.transactions_total = response.data.total;
        this.tables = response.data?.tables ?? [];
      }).catch(() => {
        this.$notify({
          group: "notifications",
          type: "error",
          title: "Fout opgetreden",
          text: "Oeps er ging iets fout..",
        }, 2000);
      });
    },

    resetFilter() {
      this.filters = {
        ...DEFAULT_FILTERS,
      };
    },
  },

  computed: {
    isResetFilterClickable() {
      return Object.keys(this.filters).some(key => this.filters[key] !== DEFAULT_FILTERS[key]);
    },

    terminal() {
      return this.$store.getters["terminal/terminal"];
    },
  },
};
</script>

<template>
  <navigation-mobile-pos
    :title="`${$t('translations.views.mobilepos.pages.recent-transactions.title')} ${transactions_total > 0 ? '#' + transactions_total : ''}`"
    :current_page="'recent-transactions'"
    tableHeader="true"
  />

  <modal-mail-receipt
    ref="mailreceipt"
    mode="mobilepos"
    :transaction_prop="pendingTransaction"
  />

  <div class="flex flex-col overflow-y-auto" v-cloak>
    <div class="bg-gray-900 z-10 flex-grow px-4">
      <h3 class="sr-only">Filters</h3>

      <div class="flex justify-start items-center gap-4">
        <!-- Date -->
        <select
          v-model="filters.date"
          class="select appearance-none rounded bg-gray-100 py-1 px-2 block w-full focus:outline-none w-[240px]"
        >
          <option value="" selected>Filter by time</option>
          <option value="30minute">Last 30 minutes</option>
          <option value="1hour">Last hour</option>
          <option value="4hour">Last 4 hours</option>
          <option value="8hour">Last 8 hours</option>
          <option value="12hour">Last 12 hours</option>
          <option value="1day">Last day</option>
        </select>

        <payment-type-select v-model="filters.payment_type" />

        <select
          v-model="filters.table"
          class="select appearance-none rounded bg-gray-100 py-1 px-2 block w-full focus:outline-none"
        >
          <option value="" selected>Selecteer tafel</option>
          <option v-for="(table, index_table) in tables" :key="index_table" :value="table.id">
            {{ table.name }}
          </option>
        </select>

        <button
          @click.prevent="resetFilter"
          :disabled="!isResetFilterClickable"
          class="w-8 h-8 inline-flex items-center justify-center rounded text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          :class="{
            'opacity-50 cursor-not-allowed': !isResetFilterClickable
          }"
        >
          <span class="sr-only">Reset</span>
          <svg class="w-5 h-5" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.85355 2.14645C5.04882 2.34171 5.04882 2.65829 4.85355 2.85355L3.70711 4H9C11.4853 4 13.5 6.01472 13.5 8.5C13.5 10.9853 11.4853 13 9 13H5C4.72386 13 4.5 12.7761 4.5 12.5C4.5 12.2239 4.72386 12 5 12H9C10.933 12 12.5 10.433 12.5 8.5C12.5 6.567 10.933 5 9 5H3.70711L4.85355 6.14645C5.04882 6.34171 5.04882 6.65829 4.85355 6.85355C4.65829 7.04882 4.34171 7.04882 4.14645 6.85355L2.14645 4.85355C1.95118 4.65829 1.95118 4.34171 2.14645 4.14645L4.14645 2.14645C4.34171 1.95118 4.65829 1.95118 4.85355 2.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>
    </div>

    <div class="flex-grow px-4 my-4" v-if="transactions.length == 0">
      {{ $t("translations.components.modal-transactions.no-transactions-available") }}
    </div>

    <div class="flex-grow px-4 my-4" v-else>
      <ul class="">
        <li
          class="pb-12 relative space-y-2"
          v-for="(transaction, index_transaction) in transactions" :key="index_transaction"
        >
          <div class="flex items-start py-1 px-2 font-bold grid grid-cols-10 mt-1 text-xs border-t">
            <div class="flex flex-wrap justify-start col-span-7 flex-cols-7">
              <div class="col-span-2 pr-2">{{ transaction.attributes[terminal.receipt_reference] }}</div>
              <div class="col-span-5">{{ transaction.attributes.time }} {{ transaction.attributes.date }}</div>
            </div>
            <div class="col-span-1">
              <span v-if="transaction.attributes.status == 'canceled'" class="text-red-500">Geannuleerd</span>
              <span v-else-if="transaction.attributes.status == 'completed'" class="text-green-500">Voltooid</span>
            </div>
            <div class="col-span-2 text-right">
              {{ vueNumberFormat(transaction.attributes.total) }}
            </div>
          </div>

          <div class="flex flex-row-reverse gap-2">
            <span class="flex justify-end rounded-sm" v-if="transaction.attributes.status == 'completed'">
              <a @click="mailReceipt(transaction)" class="button button-ghost button-small">
                  Bon mailen
              </a>
            </span>

            <span class="flex justify-end rounded-sm" v-if="transaction.attributes.status == 'completed'">
              <a @click="printReceipt(transaction.id)" class="button button-ghost button-small">
                {{ $t("translations.components.modal-transactions.print") }}
              </a>
            </span>
          </div>

          <div
            v-for="(line, index_line) in transaction.relationships.lines"
            :key="index_line"
            class="py-1 px-2 grid grid-cols-12 mt-1 text-xs text-left"
          >
            <div class="col-span-1">{{ parseFloat(line.attributes.quantity) }}</div>
            <div class="col-span-6">{{ line.attributes.description }}</div>
            <div class="col-span-3 font-medium">
              {{
                index_line === 0
                  ? (transaction?.relationships?.table?.attributes?.name ?? "")
                  : ""
              }}
            </div>
            <div class="col-span-2 text-right">{{ vueNumberFormat(line.attributes.total) }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
