<script>
import axios from "axios";

export default {
  name: "PaymentTypeSelect",

  props: ["modelValue"],
  emits: ["update:modelValue"],

  data: () => ({
    paymentTypes: null,
  }),

  mounted() {
    this.loadPaymentTypes();
  },

  methods: {
    async loadPaymentTypes() {
      let dataPaymenttypes = JSON.parse(localStorage.getItem("masterdata.paymenttypes"));

      if (!dataPaymenttypes) {
        try {
          const response = await axios({
            method: "GET",
            url: "/paymenttypes",
          });

          dataPaymenttypes = response.data.data;
          localStorage.setItem("masterdata.paymenttypes", JSON.stringify(dataPaymenttypes));
        } catch (error) {
          console.error(error);
          localStorage.removeItem("masterdata.paymenttypes");

          this.$notify({
            group: "notifications",
            type: "error",
            title: "Fout opgetreden",
            text: "Oeps er ging iets fout..",
          }, 2000);

          return;
        }
      }

      this.paymentTypes = dataPaymenttypes;
    },
  },

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<template>
  <select
    :disabled="!paymentTypes"
    v-model="value"
    class="select appearance-none rounded bg-gray-100 py-1 px-2 block w-full focus:outline-none"
  >
    <option value="" selected>Selecteer betaalmethode</option>
    <option
      v-for="paymentType in (paymentTypes ?? [])"
      :key="paymentType.id"
      :value="paymentType.id"
    >
      {{ paymentType?.attributes?.name ?? "" }}
    </option>
  </select>
</template>
